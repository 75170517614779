@use '@/styles/utils/mixins.scss' as *;

.findSolutionWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;

  @include tab() {
    grid-template-columns: repeat(1, 1fr);
    padding: 0 1.875rem;
  }

  .headingWrapper {
    @include mob() {
      margin-bottom: 3.75rem;
      text-align: center;
    }

    .ctaBannerTitle {
      display: flex;
      flex-direction: column;

      @include tab() {
        gap: 0.5rem;
        justify-content: center;
      }

      @include mob() {
        flex-direction: row;
      }

      span {
        color: $dark;
      }
      @include tab() {
        text-align: center;
        align-items: center;
      }
    }
  }

  .rightWrapper {
    grid-column: span 2;

    @include tab() {
      grid-column: span 1;
      text-align: center;
    }
    .description {
      margin-bottom: 3.75rem;
      line-height: 1.52rem;
      font-weight: 400;

      @include mob() {
        line-height: 1.2rem;
        text-align: center;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-weight: bold;
        font-size: 1.5rem;
        @include tab() {
          font-size: 1.25rem;
        }
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
        &:not(:first-child) {
          margin-top: 1rem;
        }
      }

      p {
        margin-bottom: 1rem;
      }
      ul,
      ol {
        padding-left: 1.5rem;
        margin-bottom: 1rem;
      }
      a {
        color: $primary;
        &:hover,
        &:focus,
        &:active {
          color: $primaryAlt;
        }
      }
    }
  }
}
